var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.7/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.strict, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div data-full-path=\""
    + alias4((((helper = lookupProperty(helpers,"full_path") || alias1(depth0, "full_path", {"start":{"line":10,"column":32},"end":{"line":10,"column":41}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"full_path","hash":{},"data":data,"loc":{"start":{"line":10,"column":29},"end":{"line":10,"column":44}}}) : helper)))
    + "\" data-line-no=\""
    + alias4((((helper = lookupProperty(helpers,"line_number") || alias1(depth0, "line_number", {"start":{"line":10,"column":63},"end":{"line":10,"column":74}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"line_number","hash":{},"data":data,"loc":{"start":{"line":10,"column":60},"end":{"line":10,"column":77}}}) : helper)))
    + "\">\n            <div class=\"stackframe\">\n                <i class=\"fa fa-caret-right expand\"></i>\n                <span class=\"subtle\">at</span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,lookupProperty(depth0,"function_name"),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":16},"end":{"line":16,"column":23}}})) != null ? stack1 : "")
    + "                <span class=\"subtle\">"
    + alias4((((helper = lookupProperty(helpers,"show_path") || alias1(depth0, "show_path", {"start":{"line":17,"column":40},"end":{"line":17,"column":49}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"show_path","hash":{},"data":data,"loc":{"start":{"line":17,"column":37},"end":{"line":17,"column":52}}}) : helper)))
    + ":"
    + alias4((((helper = lookupProperty(helpers,"line_number") || alias1(depth0, "line_number", {"start":{"line":17,"column":56},"end":{"line":17,"column":67}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"line_number","hash":{},"data":data,"loc":{"start":{"line":17,"column":53},"end":{"line":17,"column":70}}}) : helper)))
    + "</span>\n            </div>\n            <div class=\"code-context\" style=\"display: none\">\n                <div class=\"code-context-content\">"
    + ((stack1 = lookupProperty(helpers,"each").call(alias2,lookupProperty(depth0,"context"),{"name":"each","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":20},"end":{"line":23,"column":31}}})) != null ? stack1 : "")
    + "</div>\n            </div>\n        </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + alias3(alias2(alias1(lookupProperty(depth0,"function_name"), "scope", {"start":{"line":15,"column":19},"end":{"line":15,"column":38}} ), depth0))
    + "<b>"
    + alias3(alias2(alias1(lookupProperty(depth0,"function_name"), "name", {"start":{"line":15,"column":47},"end":{"line":15,"column":65}} ), depth0))
    + "</b>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.strict, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"focus"),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":29},"end":{"line":22,"column":67}}})) != null ? stack1 : "")
    + "><span class=\"line-number\">"
    + alias4((((helper = lookupProperty(helpers,"line_number") || alias2(depth0, "line_number", {"start":{"line":22,"column":97},"end":{"line":22,"column":108}} ))),(typeof helper === alias3 ? helper.call(alias1,{"name":"line_number","hash":{},"data":data,"loc":{"start":{"line":22,"column":94},"end":{"line":22,"column":111}}}) : helper)))
    + "</span> "
    + alias4((((helper = lookupProperty(helpers,"line") || alias2(depth0, "line", {"start":{"line":22,"column":122},"end":{"line":22,"column":126}} ))),(typeof helper === alias3 ? helper.call(alias1,{"name":"line","hash":{},"data":data,"loc":{"start":{"line":22,"column":119},"end":{"line":22,"column":129}}}) : helper)))
    + "</div>";
},"5":function(container,depth0,helpers,partials,data) {
    return "class=\"focus-line\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"stacktrace-header\">\n    <div class=\"warning-symbol\">\n        <i class=\"fa fa-exclamation-triangle\"></i>\n    </div>\n    <div class=\"message\"><strong>Error:</strong> "
    + container.escapeExpression((((helper = lookupProperty(helpers,"error") || container.strict(depth0, "error", {"start":{"line":5,"column":52},"end":{"line":5,"column":57}} ))),(typeof helper === "function" ? helper.call(alias1,{"name":"error","hash":{},"data":data,"loc":{"start":{"line":5,"column":49},"end":{"line":5,"column":60}}}) : helper)))
    + "</div>\n    <div class=\"exit\"></div>\n</div>\n<div class=\"stacktrace-content\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,lookupProperty(depth0,"stackframes"),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":4},"end":{"line":27,"column":13}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});